import {
    ACCOUNT_FIELDS,
    ADDED_DOCUMENT,
    CLEAR_PROFILE,
    DOCUMENTS,
    FILE_UPLOAD_TYPES,
    FILLED_IN_FLEXAPP_DATA,
    FLEXAPP_ORGANIZATIONS,
    HIDE_FLEXAPP_NOTIFICATION,
    ORGANIZATIONS,
    PROFILE_ADD_ERROR,
    PROFILE_ADDED_ITEM,
    PROFILE_ADDING_ITEM,
    PROFILE_DELETED_ITEM,
    PROFILE_EDIT_ERROR,
    PROFILE_EDITED_ITEM,
    PROFILE_EDITING_ITEM,
    PROFILE_FAILURE,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_UPDATE_ERROR,
    PROFILE_UPDATE_ITEM,
    PROFILE_UPDATED_ITEM,
    SCHOOLS,
    SCHOOLS_REQUEST,
    SET_EVENTS_OF_INTEREST,
    SET_EVENTS_REGISTERED_FOR,
    SET_POSSIBLE_EVENT_INTERESTS,
    SET_PROFILE_PICTURE,
    SHOW_FLEXAPP_NOTIFICATION,
    SIGN_OUT_FROM_EVENT,
    SIGN_UP_FOR_EVENT,
    STUDIES,
    STUDY_LEVELS,
    VACANCY_REACTION,
    VACANCY_REACTION_UPDATED,
    VACANCY_REACTIONS,
    WORKING_AREAS,
    FLEXAPP_ORGANIZATIONS_PUBLIC,
} from '../../constants/Constants'
import Raven from 'raven-js'
import newCallApi from './api'
import { tokenUsed, updateUserData } from './auth'
import { store } from '../index'

function requestProfile() {
    return {
        type: PROFILE_REQUEST,
    }
}

function profileSuccess(profile) {
    return {
        type: PROFILE_SUCCESS,
        profile: profile,
    }
}

function profileFailure(error) {
    return {
        type: PROFILE_FAILURE,
        error: error,
    }
}

export function fetchProfile(auth, onError = (status, error) => {}, onSuccess = () => {}) {
    auth = store.getState().auth
    var path = store.getState().config.API_PROFILE + '/' + auth.id

    return (dispatch) => {
        dispatch(requestProfile())

        return newCallApi('GET', path, null, /* auth */ null).then(
            (profile) => {
                dispatch(tokenUsed())
                dispatch(profileSuccess(profile))
                dispatch(updateUserData(profile.first_name, profile.last_name))
                dispatch(getProfilePicture(auth, profile))
                Raven.setUserContext({
                    email: profile.email,
                    id: profile.id,
                })
                let user = store.getState().user
                // Show the yes / no question if the user is a normal account and the current employer uses flexapp
                let suggestFlexapp =
                    store.getState().config.whitelabel.flexapp.used &&
                    !profile.flexapp_invitation_rejected &&
                    profile.account_types &&
                    !profile.account_types.some(
                        (type) =>
                            type.code === 'corona' ||
                            type.code === 'flexapp-employee' ||
                            type.code === 'planner',
                    ) &&
                    user.flexapp_organizations &&
                    user.flexapp_organizations.some((org) => org.id === profile.organisation_id)

                suggestFlexapp
                    ? dispatch(showFlexappNotification())
                    : !store.getState().user.showFlexappNotification &&
                      dispatch(hideFlexappNotification())
                onSuccess()
            },
            (response) => {
                dispatch(profileFailure(response.error))
                onError(response.status, response)
            },
        )
    }
}

function workingAreaSuccess(areas) {
    return {
        type: WORKING_AREAS,
        list: areas,
    }
}

export function fetchWorkingAreas(auth, onError = (status, error) => {}) {
    var path = store.getState().config.API_WORKING_AREAS

    return (dispatch) => {
        return newCallApi('GET', path, null, /* auth */ null).then(
            (list) => {
                //Success
                dispatch(tokenUsed())
                dispatch(workingAreaSuccess(list))
            },
            (response) => {
                //Error
                onError(response.status, response)
            },
        )
    }
}

function studySuccess(areas) {
    return {
        type: STUDIES,
        list: areas,
    }
}

export function fetchStudies(auth, onError = (status, error) => {}) {
    return (dispatch) => {
        return newCallApi('GET', store.getState().config.API_STUDIES, null, /* auth */ null).then(
            (list) => {
                //Success
                dispatch(tokenUsed())
                dispatch(studySuccess(list))
            },
            (response) => {
                onError(response.status, response)
            },
        )
    }
}

function setStudyLevels(levels) {
    return {
        type: STUDY_LEVELS,
        list: levels,
    }
}

export function fetchStudyLevels(auth, onError = (status, error) => {}) {
    return (dispatch) => {
        return newCallApi(
            'GET',
            store.getState().config.API_STUDY_LEVELS,
            null,
            /* auth */ null,
        ).then(
            (list) => {
                dispatch(tokenUsed())
                dispatch(setStudyLevels(list))
            },
            (response) => {
                onError(response.status, response)
            },
        )
    }
}

export function fetchSchools(auth, onError = (status, error) => {}) {
    return (dispatch) => {
        dispatch(schoolsRequest())

        return newCallApi('GET', store.getState().config.API_SCHOOLS, null, /* auth */ null).then(
            (list) => {
                //Success
                dispatch(tokenUsed())
                dispatch(setSchools(list))
            },
            (response) => {
                onError(response.status, response)
            },
        )
    }
}

function setSchools(schools) {
    return {
        type: SCHOOLS,
        list: schools,
    }
}

function schoolsRequest() {
    return {
        type: SCHOOLS_REQUEST,
        loading: true,
    }
}

function addingItem(type) {
    return {
        type: PROFILE_ADDING_ITEM,
        addingType: type,
    }
}

function addedItem(item, type) {
    return {
        type: PROFILE_ADDED_ITEM,
        item: item,
        field: type,
    }
}

function deletedItem(item, type) {
    return {
        type: PROFILE_DELETED_ITEM,
        item: item,
        field: type,
    }
}

function addError(err) {
    return {
        type: PROFILE_ADD_ERROR,
        error: err,
    }
}

export function addProfileItem(type, item, auth) {
    return async (dispatch) => {
        dispatch(addingItem(type))
        let path = getProfileItemUrl(auth, type, null)
        return newCallApi('POST', path, item, /* auth */ null)
    }
}

export function addProfileItems(type, items, auth, onSuccess = () => {}, onError = () => {}) {
    return (dispatch) => {
        let promises = []
        items.forEach((item) => {
            promises.push(dispatch(addProfileItem(type, item, auth, onSuccess, onError)))
        })
        Promise.all(promises)
            .then((object) => {
                dispatch(addedItem(object, type))
                onSuccess()
            })
            .catch((response) => {
                console.log('[ Add Profile Item ]', 'Error', response)
                dispatch(addError(response.error))
                onError(response.status, response)
            })
    }
}

export function deleteItem(type, id, auth) {
    return (dispatch) => {
        let path = getProfileItemUrl(auth, type, id)
        return newCallApi('DELETE', path, null, /* auth */ null, true, false)
    }
}

export function deleteProfileItems(type, items, auth, onSuccess, onError) {
    return async (dispatch) => {
        let promises = []
        items.forEach((item) => {
            promises.push(dispatch(deleteItem(type, item, auth)))
        })
        Promise.all(promises)
            .then((object) => {
                dispatch(deletedItem(items, type))
                onSuccess()
            })
            .catch((response) => {
                onError(response.status, response)
            })
    }
}

export function diffProfileItems(
    type,
    toAdd,
    toRemove,
    auth,
    onSuccess = () => {},
    onError = () => {},
    getProfile = true,
) {
    return async (dispatch) => {
        let done = false

        function success() {
            if (!done) {
                done = !done
            } else {
                if (getProfile) {
                    dispatch(
                        fetchProfile(auth, (status, errorObj) => {
                            console.log(errorObj)
                        }),
                    )
                }
                onSuccess()
            }
        }

        dispatch(addProfileItems(type, toAdd, auth, success, onError))
        dispatch(deleteProfileItems(type, toRemove, auth, success, onError))
    }
}

function updatingItem(type) {
    return {
        type: PROFILE_UPDATE_ITEM,
        updateType: type,
    }
}

function updatedItem(item) {
    return {
        type: PROFILE_UPDATED_ITEM,
        item: item,
    }
}

function updateError(item) {
    return {
        type: PROFILE_UPDATE_ERROR,
        item: item,
    }
}

export function updateProfileItem(type, item, auth, onSuccess, onError) {
    return async (dispatch) => {
        dispatch(updatingItem(type))
        let path = store.getState().config.API_PROFILE + '/' + auth.id
        return newCallApi('PUT', path, item, /* auth */ null).then(
            (object) => {
                dispatch(updatedItem(object))
                onSuccess()
            },
            (response) => {
                console.log('[ Add Profile Item ]', 'Error', response)
                dispatch(updateError(response.error))
                onError(response.status, response)
            },
        )
    }
}

function editingItem() {
    return {
        type: PROFILE_EDITING_ITEM,
    }
}

function editedItem() {
    return {
        type: PROFILE_EDITED_ITEM,
    }
}

export function editError(err) {
    return {
        type: PROFILE_EDIT_ERROR,
        error: err,
    }
}

export function getProfileItemUrl(auth, type, id) {
    let path = store.getState().config.API_PROFILE + '/' + auth.id

    console.log('[ Get Profile Item Url ]', auth, type, id)
    if (id) {
        if (type === 'general') {
            return path
        } else if (type === 'fields') {
            return path + `/${type}`
        } else if (type === 'applications') {
            return path.replace('account', 'clients') + `/${type}/${id}`
        } else {
            return path + `/${type}/${id}`
        }
    } else {
        if (type === 'general') {
            return path
        } else if (type === 'fields') {
            return path + `/${type}`
        } else if (type === 'applications') {
            return path.replace('account', 'clients') + `/${type}/${id}`
        } else {
            return path + `/${type}`
        }
    }
}

export function editProfileItem(type, item, auth, onSuccess, onError, getProfile = true) {
    return (dispatch) => {
        dispatch(editingItem())
        let path = getProfileItemUrl(auth, type, item.id)
        return newCallApi('PUT', path, item, /* auth */ null, true, false).then(
            () => {
                if (getProfile) {
                    dispatch(fetchProfile(auth, (status, errorObj) => {}))
                }
                dispatch(editedItem())
                onSuccess()
            },
            (response) => {
                dispatch(editError(response.error))
                onError(response.status, response)
            },
        )
    }
}

export function clearProfileState() {
    return {
        type: CLEAR_PROFILE,
    }
}

export function getProfilePictureUrl(account_id) {
    return store.getState().config.API_PICTURE.replace('{account}', account_id)
}

export function uploadProfilePicture(auth, picture, success, error) {
    var data = new FormData()
    data.append('profile_picture', picture)
    newCallApi('POST', getProfilePictureUrl(auth.id), data, /* auth */ null, false, false).then(
        (response) => {
            success()
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function removeProfilePicture(auth, success, error) {
    newCallApi('DELETE', getProfilePictureUrl(auth.id), null, /* auth */ null, true, false).then(
        (response) => {
            success()
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function getProfilePicture(auth, profile, app = false) {
    return (dispatch) => {
        if (auth.isAuthenticated && profile && profile.has_profile_picture) {
            newCallApi(
                'GET',
                store.getState().config.API_PROFILE + `/${auth.id}/profilepicture`,
                null,
                /* auth */ null,
                false,
                true,
                'blob',
            ).then(
                (res) => {
                    let urlCreator = window.URL || window.webkitURL
                    let url = urlCreator.createObjectURL(res)
                    dispatch({
                        type: SET_PROFILE_PICTURE,
                        url,
                    })
                },
                (err) => {
                    dispatch({
                        type: SET_PROFILE_PICTURE,
                        url: getFallbackProfilePicture(app),
                    })
                },
            )
        } else {
            //TODO return default image
            dispatch({
                type: SET_PROFILE_PICTURE,
                url: getFallbackProfilePicture(app),
            })
        }
    }
}

function getFallbackProfilePicture(app) {
    let imagename = 'avatar-placeholder.png'

    if (app) {
        return imagename
    } else {
        return '/images/' + imagename
    }
}

export function getRoles(auth, success, error) {
    newCallApi('GET', store.getState().config.API_ROLES, null, /* auth */ null).then(
        (json) => {
            success(json)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function getPrivacyOptions(auth, success, error) {
    return (dispatch) => {
        newCallApi(
            'GET',
            store.getState().config.API_PRIVACY.replace('{account}', auth.id),
            null,
            /* auth */ null,
        ).then(
            (json) => {
                success(json)
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function getCompetences(auth, success, error) {
    newCallApi('GET', store.getState().config.API_COMPETENCES, null, /* auth */ null).then(
        (json) => {
            success(json)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function getCleanCompetences(success, error = () => {}) {
    return newCallApi('GET', store.getState().config.API_COMPETENCES_CLEAN, null, null).then(
        (json) => {
            success(json)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function getTopics(auth, success, error) {
    newCallApi('GET', store.getState().config.API_TOPICS, null, /* auth */ null).then(
        (json) => {
            success(json)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function addRoles() {}

export function getJobs(auth, flexapp, success, error = () => {}) {
    let path = flexapp
        ? store.getState().config.API_FLEX_JOB_FUNCTIONS
        : store.getState().config.API_JOBS

    newCallApi('GET', path, null, /* auth */ null).then(
        (json) => {
            success(json)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

export function fetchOrganizations(auth, success, error) {
    return (dispatch) => {
        return newCallApi(
            'GET',
            store.getState().config.API_ORGANIZATIONS,
            null,
            /* auth */ null,
        ).then(
            (json) => {
                dispatch({
                    type: ORGANIZATIONS,
                    organizations: json,
                })
                success()
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function fetchFlexappPublicOrganizations(auth, success, error = () => {}) {
    return (dispatch) => {
        return newCallApi(
            'GET',
            store.getState().config.API_FLEXAPP_ORGANIZATIONS_PUBLIC,
            null,
            auth,
        ).then(
            (json) => {
                dispatch({
                    type: FLEXAPP_ORGANIZATIONS_PUBLIC,
                    flexapp_organizations: json,
                })
                success()
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function fetchFlexappOrganizations(auth, success, error = () => {}) {
    return (dispatch) => {
        return newCallApi(
            'GET',
            store.getState().config.API_FLEXAPP_ORGANIZATIONS,
            null,
            auth,
        ).then(
            (json) => {
                dispatch({
                    type: FLEXAPP_ORGANIZATIONS,
                    flexapp_organizations: json,
                })
                success()
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function fetchDocumentsList(auth, success = () => {}) {
    return (dispatch) => {
        let path = store.getState().config.DOCUMENT_TYPES.replace('{type}', 1)
        return newCallApi('GET', path, null, /* auth */ null).then(
            (json) => {
                dispatch({
                    type: FILE_UPLOAD_TYPES,
                    file_upload_types: json,
                })
                success(json)
            },
            () => {},
        )
    }
}

export function fetchAccountFields(account_types, auth, success = () => {}) {
    return (dispatch) => {
        if (!account_types) return

        // @todo get all the fields for all the account types in this account instead of only one
        let promises = []
        let responses = []
        account_types.forEach((type) => {
            var path = store.getState().config.API_ACCOUNT_TYPES + '/' + type.id + '/fields'
            promises.push(
                newCallApi('GET', path, null, /* auth */ null).then(
                    (json) => {
                        if (json) {
                            responses.push(json)
                        }
                    },
                    () => {},
                ),
            )
        })
        Promise.all(promises).then(() => {
            let resp = []
            responses.forEach((arr) => {
                arr.forEach((el) => {
                    if (!resp.some((res) => res.id === el.id)) {
                        resp.push(el)
                    }
                })
            })

            dispatch({
                type: ACCOUNT_FIELDS,
                account_fields: responses[0],
            })
            success(responses)
        })
    }
}

export function getEventsRegisteredFor(auth, success = () => {}, error = () => {}) {
    return (dispatch) => {
        var path = store.getState().config.API_EVENTS + '?registered=true'
        return newCallApi('GET', path, null, /* auth */ null).then(
            (json) => {
                if (json) {
                    dispatch({
                        type: SET_EVENTS_REGISTERED_FOR,
                        events: json,
                    })
                    success(json)
                }
            },
            (err) => error(err),
        )
    }
}

export function getEventsOfInterest(interests, auth, success = () => {}, error = () => {}) {
    if (!interests || interests.length === 0) return (dispatch) => {}
    return (dispatch) => {
        let path =
            store.getState().config.API_EVENTS +
            '?interest=' +
            interests.join(',') +
            '&period=future' +
            '&registered=false'
        return newCallApi('GET', path, null, /* auth */ null).then(
            (json) => {
                if (json) {
                    dispatch({
                        type: SET_EVENTS_OF_INTEREST,
                        events: json,
                    })
                    success(json)
                }
            },
            (err) => error(err),
        )
    }
}

export function signUpForEvent(event, auth, success = () => {}, error = () => {}) {
    const path = store.getState().config.API_EVENTS + '/' + event.id + '/register'
    return (dispatch) => {
        newCallApi('POST', path, null, auth).then(
            (json) => {
                if (json) {
                    dispatch({
                        type: SIGN_UP_FOR_EVENT,
                        event,
                    })
                    success(json)
                }
            },
            (err) => error(err),
        )
    }
}

export function signOutFromEvent(eventID, auth, success = () => {}, error = () => {}) {
    const path = store.getState().config.API_EVENTS + '/' + eventID + '/deregister'
    return (dispatch) => {
        newCallApi('POST', path, null, auth).then(
            (json) => {
                if (json) {
                    dispatch({
                        type: SIGN_OUT_FROM_EVENT,
                        eventID,
                    })
                    success(json)
                }
            },
            (err) => error(err),
        )
    }
}

/**
 * Get all available event interests
 * @param auth
 * @param success
 * @param error
 * @returns {function(...[*]=)}
 */
export function fetchEventInterests(auth, success = () => {}, error = () => {}) {
    return (dispatch) => {
        var path = store.getState().config.API_GET_FIELDS_OF_INTEREST
        return newCallApi('GET', path, null, null).then(
            (json) => {
                if (json) {
                    dispatch({
                        type: SET_POSSIBLE_EVENT_INTERESTS,
                        interests: json,
                    })
                    success(json)
                }
            },
            (err) => error(err),
        )
    }
}

function documentsSuccess(documents) {
    return {
        type: DOCUMENTS,
        docs: documents,
    }
}

export function getDocumentsUrl(account_id, file_id = null) {
    let url = store.getState().config.API_DOCUMENTS.replace('{account}', account_id)
    if (!file_id) {
        return url
    }

    return url + `/${file_id}`
}

/**
 * Get a list of all of the documents that the user has uploaded to the system
 * @param auth
 * @param success
 * @param error
 * @returns {function(*)}
 */
export function getDocuments(auth, success, error) {
    return (dispatch) => {
        return newCallApi('GET', getDocumentsUrl(auth.id), null, /* auth */ null).then(
            (json) => {
                dispatch(documentsSuccess(json))
                success(json)
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

function vacancyReactionSuccess(reactions) {
    return {
        type: VACANCY_REACTIONS,
        reactions: reactions,
    }
}

export function getVacancyReactions(auth, success, error) {
    return (dispatch) => {
        return newCallApi(
            'GET',
            store.getState().config.API_VACANCY_REACTIONS,
            null,
            /* auth */ null,
        ).then(
            (json) => {
                dispatch(vacancyReactionSuccess(json))
                success(json)
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function addedDocument(json) {
    return {
        type: ADDED_DOCUMENT,
        document: json,
    }
}

export function uploadCV(auth, cv) {
    let data = new FormData()
    data.append('file', cv)

    return new Promise((resolve, reject) => {
        newCallApi(
            'POST',
            getDocumentsUrl(auth.id) + '?code=cv',
            data,
            /* auth */ null,
            false,
        ).then(
            (response) => {
                resolve(response)
            },
            (response) => {
                reject({
                    status: response.status,
                    errors: response,
                })
            },
        )
    })
}

function savedApplication(application) {
    return {
        type: VACANCY_REACTION,
        application: application,
    }
}

export function saveApplication(id, application, auth, success, error) {
    let obj = application
    obj.vacancy_id = id

    return (dispatch) => {
        newCallApi(
            'POST',
            store.getState().config.API_VACANCY_REACTIONS,
            obj,
            /* auth */ null,
        ).then(
            (json) => {
                dispatch(savedApplication(json))
                dispatch(
                    getVacancyReactions(
                        auth,
                        () => {},
                        () => {},
                    ),
                )
                success(json)
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

function updatedApplication(application) {
    return {
        type: VACANCY_REACTION_UPDATED,
        application: application,
    }
}

export function updateApplication(id, job_offer, application, auth, success, error) {
    let obj = application
    obj.vacancy_id = job_offer

    return (dispatch) => {
        newCallApi(
            'PATCH',
            store.getState().config.API_VACANCY_REACTIONS + '/' + id,
            obj,
            /* auth */ null,
        ).then(
            (json) => {
                dispatch(updatedApplication(json))
                success(json)
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function removeConceptApplication(id, auth, success, error) {
    return async (dispatch) => {
        return newCallApi(
            'DELETE',
            store.getState().config.API_VACANCY_REACTIONS + '/' + id,
            null,
            /* auth */ null,
            true,
            false,
        ).then(
            () => {
                dispatch(
                    getVacancyReactions(
                        auth,
                        () => {
                            success()
                        },
                        (status, errorObj) => {
                            console.log(errorObj)
                        },
                    ),
                )
            },
            (response) => {
                error(response.status, response)
            },
        )
    }
}

export function exportPortfolioEmail(auth) {
    return newCallApi(
        'POST',
        store.getState().config.API_EMAIL_EXPORT,
        null,
        /* auth */ null,
        true,
        false,
    )
}

export function getResumes(auth) {
    return new Promise((resolve, reject) => {
        newCallApi('GET', store.getState().config.API_RESUMES, null, /* auth */ null).then(
            (json) => {
                resolve(json)
            },
            (response) => {
                reject(response)
            },
        )
    })
}

//TODO fix the api urls for the resume management
/**
 * Create a new resume
 * @param auth
 * @param body
 * @returns Promise
 */
export function addResume(auth, body) {
    return new Promise((resolve, reject) => {
        newCallApi('POST', store.getState().config.API_RESUMES, body, /* auth */ null).then(
            (json) => {
                resolve(json)
            },
            (response) => {
                reject(response)
            },
        )
    })
}

/**
 * Edit a specific resume
 * @param id
 * @param auth
 * @param body
 * @returns Promise
 */
export function editResume(id, auth, body) {
    return new Promise((resolve, reject) => {
        newCallApi(
            'PUT',
            store.getState().config.API_RESUMES + '/' + id,
            body,
            /* auth */ null,
        ).then(
            (response) => {
                resolve(response)
            },
            (response) => {
                reject(response.status, response)
            },
        )
    })
}

/**
 * Delete a specific resume
 * @param id
 * @param auth
 * @param body
 * @returns Promise
 */
export function deleteResume(id, auth) {
    return new Promise((resolve, reject) => {
        newCallApi(
            'DELETE',
            store.getState().config.API_RESUMES + '/' + id,
            null,
            /* auth */ null,
            true,
            false,
        ).then(
            (response) => {
                resolve()
            },
            (response) => {
                reject(response.status, response)
            },
        )
    })
}

/**
 * Get a specific resume.
 * @param id
 * @param auth
 * @returns Promise
 */
export function getResume(id, auth) {
    return new Promise((resolve, reject) => {
        newCallApi(
            'GET',
            store.getState().config.API_RESUMES + '/' + id,
            null,
            /* auth */ null,
        ).then(
            (object) => {
                resolve(object)
            },
            (response) => {
                reject(response.status, response)
            },
        )
    })
}

/**
 * Delete the user's account
 * @param auth
 * @returns {Promise}
 */
export function deleteAccount(auth) {
    var path = store.getState().config.API_PROFILE + '/' + auth.id

    return new Promise((resolve, reject) => {
        newCallApi('DELETE', path, null, /* auth */ null, true, false).then(
            (response) => {
                resolve()
            },
            (response) => {
                reject(response)
            },
        )
    })
}

export function addToResume(auth, resume_id, table, element_id) {
    let path = store.getState().config.API_RESUMES + `/${resume_id}/elements`

    return new Promise((resolve, reject) => {
        newCallApi(
            'POST',
            path,
            {
                table,
                element_id,
            },
            /* auth */ null,
        ).then(
            (response) => {
                resolve()
            },
            (errorResponse) => {
                errorResponse.json().then((errorObject) => {
                    reject({
                        status: errorResponse.status,
                        errors: errorObject,
                    })
                })
            },
        )
    })
}

export function getFlexappOrganisations(auth) {
    let path = store.getState().config.API_FLEX_EMPLOYERS

    return new Promise((resolve, reject) => {
        newCallApi('GET', path, null, null).then(
            (response) => {
                resolve(response)
            },
            (errorResponse) => {
                errorResponse.json().then((errorObject) => {
                    reject({
                        status: errorResponse.status,
                        errors: errorObject,
                    })
                })
            },
        )
    })
}

export function fetchSchoolSubjects(auth) {
    let path = store.getState().config.API_SCHOOL_SUBJECTS

    return new Promise((resolve, reject) => {
        newCallApi('GET', path, null, null).then(
            (object) => {
                resolve({ subjects: object })
            },
            (response) => {
                reject(response.status, response)
            },
        )
    })
}

export function fetchSchoolSubjectLevels(auth) {
    let path = store.getState().config.API_SCHOOL_SUBJECT_LEVELS

    return new Promise((resolve, reject) => {
        newCallApi('GET', path, null, null).then(
            (object) => {
                resolve({ levels: object })
            },
            (response) => {
                reject(response.status, response)
            },
        )
    })
}

export function fetchSchoolRelatedFields(auth, cb) {
    let arr = []
    arr.push(fetchSchoolSubjects(auth))
    arr.push(fetchSchoolSubjectLevels(auth))

    Promise.all(arr).then((res) => {
        cb(
            res.reduce(function (result, current) {
                return Object.assign(result, current)
            }, {}),
        )
    })
}

export function showFlexappNotification() {
    return { type: SHOW_FLEXAPP_NOTIFICATION }
}

export function hideFlexappNotification() {
    return { type: HIDE_FLEXAPP_NOTIFICATION }
}

export function filledInFlexappData() {
    return {
        type: FILLED_IN_FLEXAPP_DATA,
        missing_flexapp_data: false,
    }
}
