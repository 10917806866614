import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'

export default class Panel extends Component {
    constructor(props) {
        super(props)

        this.state = { expanded: false }
    }

    render() {
        const { className, headerText, headerIcon } = this.props

        const childrenGroups = []
        let currentGroup = []

        const ids = []

        // split children into groups to be able to display each group
        // in a separate 'panel__body' during onboarding
        React.Children.forEach(this.props.children, (child) => {
            if (child && child.props.className === 'separate_body') {
                if (child.props.container_id) {
                    ids.push(child.props.container_id)
                }
                if (currentGroup.length > 0) {
                    childrenGroups.push(currentGroup)
                    currentGroup = []
                }
            } else {
                currentGroup.push(child)
            }
        })
        if (currentGroup.length > 0) {
            childrenGroups.push(currentGroup)
        }

        let headerIconClass = 'panel__header__icon icons ' + headerIcon

        return (
            <div className={this.props.className}>
                {(this.props.headerText || this.props.headerIcon || this.props.addButton) && (
                    <div className="panel__header">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h3>
                                            <i className={headerIconClass} /> {headerText}
                                        </h3>
                                    </td>
                                    <Hidden sm xs>
                                        {this.props.supportText ? (
                                            <td>{this.props.supportText}</td>
                                        ) : (
                                            <td />
                                        )}
                                    </Hidden>
                                    {this.props.addButton && !this.props.addSecondButton && (
                                        <td className="button_container">{this.props.addButton}</td>
                                    )}
                                    {this.props.addButton && this.props.addSecondButton && (
                                        <td className="button_container">
                                            {this.props.addButton}
                                            <div style={{ clear: 'both', height: '5px' }} />
                                            {this.props.addSecondButton}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {this.props.subHeader}

                {childrenGroups.map((group, index) => (
                    <div
                        key={index}
                        className={this.state.expanded ? 'panel__body expanded' : 'panel__body'}
                        {...(ids[index] !== undefined ? { id: ids[index] } : {})}
                    >
                        {group}
                    </div>
                ))}

                {(this.props.className === 'panel--expand' ||
                    this.props.className === 'panel--expand panel--multi__inner' ||
                    this.props.className === 'panel--events panel--expand panel--multi__inner' ||
                    this.props.className === 'panel--expand panel--multi__inner tags' ||
                    this.props.className === 'panel--expand panel--multi__inner tags--mobile' ||
                    this.props.className === 'panel--multi_inner panel--expand' ||
                    this.props.className ===
                        'panel--expand panel--multi__inner panel--multi__inner__uneditable tags' ||
                    this.props.className ===
                        'panel--expand panel--multi__inner panel--multi__inner__uneditable tags--mobile') && (
                    <div
                        className="panel__btn-expand"
                        onClick={() => {
                            this.setState({ expanded: !this.state.expanded })
                        }}
                    >
                        {!this.state.expanded && (
                            <div>
                                <i className="icons icon-arrow-down">&nbsp;&nbsp;</i>
                                <Translate value="profile.see_more" />
                            </div>
                        )}
                        {this.state.expanded && (
                            <div>
                                <i className="icons icon-arrow-up">&nbsp;&nbsp;</i>
                                <Translate value="profile.see_less" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

Panel.propTypes = {
    className: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    headerIcon: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    addButton: PropTypes.element,
    addSecondButton: PropTypes.element,
    subHeader: PropTypes.element,
}

Panel.defaultProps = {
    addButton: undefined,
    addSecondButton: undefined,
}
