import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate, Translate } from 'react-i18nify'

import { Link, NavLink } from 'react-router-dom'
import history from '../../history'
import { logoutUser } from '../../store/actions/auth'
import {
    FLEXAPP_TYPES,
    PATH_APPLICATIONS,
    PATH_PROFILE,
    PATH_PROFILE_PRIVACY,
    PATH_SECURITY,
    PATH_SHARING,
    PATH_SUGGESTIONS,
    PATH_VACANCIES,
} from '../../constants/Constants'

import { connect } from 'react-redux'
import { BigTooltip } from '../Tooltip/BigTooltip'
import { redirectToFlexapp } from '../../helpers'

class HorizontalMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: 0,
            isStudent: false,
            isFlexapp: false,
        }
    }

    componentDidMount() {
        try {
            this.setState({
                isStudent:
                    this.props.user.profile.account_types &&
                    this.props.user.profile.account_types.some((type) => type.code === 'student'),
                isFlexapp:
                    this.props.user.profile.account_types &&
                    this.props.user.profile.account_types.some(({ code }) =>
                        FLEXAPP_TYPES.includes(code),
                    ),
            })
        } catch (e) {}
    }

    componentDidUpdate(prevProps) {
        try {
            if (
                this.props.user.profile.account_types &&
                prevProps.user.profile.account_types &&
                this.props.user.profile.account_types.length !==
                    prevProps.user.profile.account_types.length
            ) {
                this.setState({
                    isStudent: this.props.user.profile.account_types.some(
                        (type) => type.code == 'student',
                    ),
                    isFlexapp:
                        this.props.user.profile.account_types &&
                        this.props.user.profile.account_types.some(({ code }) =>
                            FLEXAPP_TYPES.includes(code),
                        ),
                })
            }
        } catch (e) {}
    }

    signOut() {
        this.setState({ loading: 1 })
        this.props.dispatch(
            logoutUser(this.props.auth, () => {
                this.setState({ loading: 0 })
            }),
        )
    }

    render() {
        const { dispatch, state } = this.props

        return (
            <div className="menu--horizontal">
                <ul>
                    {!this.state.isStudent &&
                        this.props.config.whitelabel &&
                        this.props.config.whitelabel.show_suggestion_page && (
                            <li id={'menu-path-suggestions'}>
                                <NavLink activeClassName="link--active" to={'/' + PATH_SUGGESTIONS}>
                                    <Translate value="menu.suggestions" />
                                </NavLink>
                            </li>
                        )}
                    <li id={'menu-path-applications'}>
                        <NavLink activeClassName="link--active" to={'/' + PATH_APPLICATIONS}>
                            <Translate value="menu.applications" />
                        </NavLink>
                    </li>
                    <li id={'menu-path-vacancies'}>
                        <NavLink activeClassName="link--active" to={'/' + PATH_VACANCIES}>
                            <Translate value="menu.vacancies" />
                        </NavLink>
                    </li>
                    <li id={'menu-path-profile'}>
                        <NavLink activeClassName="link--active" to={'/' + PATH_PROFILE}>
                            <Translate value="menu.profile" />
                        </NavLink>
                        <div className="link--submenu">
                            <ul>
                                <li>
                                    <NavLink activeClassName="link--active" to={'/' + PATH_SHARING}>
                                        <Translate value="menu.sharing" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="link--active"
                                        to={'/' + PATH_SECURITY}
                                    >
                                        <Translate value="menu.security" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="link--active"
                                        to={'/' + PATH_PROFILE_PRIVACY}
                                    >
                                        <Translate value="menu.privacy" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="pull-right menu__log-out">
                        <Link
                            onClick={() => {
                                this.signOut()
                            }}
                            className={this.state.loading === 1 ? 'is-loading' : ''}
                        >
                            <Translate value="menu.logout" />
                        </Link>
                    </li>
                    {!this.state.isStudent && (
                        <li className="pull-right menu__onboarding">
                            <BigTooltip
                                title={translate('menu.intro')}
                                position="bottom"
                                arrow={true}
                            >
                                <NavLink
                                    activeClassName="link--active"
                                    isActive={(match, loc) =>
                                        loc.pathname.includes('onboarding=true')
                                    }
                                    to={history.location.pathname + '?number=1&onboarding=true'}
                                >
                                    <i className="icons icon-question" />
                                </NavLink>
                            </BigTooltip>
                        </li>
                    )}
                    {this.state.isFlexapp && this.props.config.whitelabel.flexapp.used && (
                        <li className="pull-right flexapp__logo">
                            <BigTooltip
                                title={this.props.config.whitelabel.flexapp.name}
                                position="bottom"
                                arrow={true}
                            >
                                <a
                                    onClick={(e) =>
                                        redirectToFlexapp(
                                            e,
                                            this.props.user.profile,
                                            this.props.config,
                                            this.props.dispatch,
                                            this.props.auth,
                                        )
                                    }
                                    target={'_blank'}
                                    href={this.props.config.whitelabel.flexapp.url}
                                    rel="noreferrer"
                                >
                                    <img
                                        src={
                                            this.props.config.whitelabel.flexapp.url +
                                            `img${this.props.config.whitelabel.name}/flexapp-logo-shadow.png`
                                        }
                                    />
                                </a>
                            </BigTooltip>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

function select(state) {
    const { auth, user, config } = state
    return {
        user,
        auth,
        config,
    }
}

HorizontalMenu.propTypes = {
    currentPage: PropTypes.string,
}

// Wrap the component to inject dispatch and state into it
export default connect(select)(HorizontalMenu)
