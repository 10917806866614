import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate, Translate } from 'react-i18nify'

import FormStudies from '../../Forms/FormStudies'
import Button from '../../Button/Button'
import { Hidden } from 'react-grid-system'
import AlertBox from '../../Alert/AlertBox'

export default class HighestStudy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            save: undefined,
            cancel: undefined,
            errors: [],
        }

        this.showErrors = this.showErrors.bind(this)
        this.save = this.save.bind(this)
        this.formRef = React.createRef()
    }

    showErrors(errors) {
        this.setState({ loading: false, errors })
    }

    getExistingEducation(studies) {
        if (studies.length > 0) {
            return studies[studies.length - 1]
        } else {
            return null
        }
    }

    save() {
        this.setState({ loading: true })
        this.formRef.current.save()
    }

    render() {
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1 className={'mixed-case'}>{translate('onboarding.alt1.step2.title')}</h1>
                    <FormStudies
                        ref={this.formRef}
                        areas={this.props.user.studies}
                        onCancel={this.props.prevStep}
                        onSave={this.props.nextStep}
                        className="form--bg"
                        studies={this.props.user.studies}
                        showErrors={this.showErrors}
                        edit={this.getExistingEducation(this.props.user.profile.studies)}
                        darkMode={false}
                        showActions={false}
                    />
                    {this.state.errors.length > 0 && (
                        <AlertBox messages={this.state.errors} type={'error'} />
                    )}

                    <div className="card--simple__buttons">
                        <div>
                            <Button
                                className={
                                    'button--alt inline-block' +
                                    (this.state.loading ? ' is-loading' : '')
                                }
                                buttonText={translate('buttons.continue')}
                                onClick={this.save}
                            />
                        </div>
                        <Hidden xs>
                            <button
                                onClick={() => {
                                    this.props.prevStep()
                                }}
                                className="button--link inline-block"
                            >
                                <i className="mdi mdi-arrow-left" />
                                <Translate value="onboarding.button_back" />
                            </button>
                        </Hidden>
                    </div>
                </div>
            </div>
        )
    }
}

HighestStudy.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}
